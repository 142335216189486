import React from "react";
import { Script } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

export default function Hero({ data }) {
  return (
    <section className="relative sm:h-[640px] h-[740px]">
      <div className="absolute top-0 left-0 bottom-0 right-0 -z-20">
        <GatsbyImage
          image={data.thumbnail.asset.gatsbyImageData}
          alt="newsletter-hero"
          placeholder="blurred"
          className="sm:h-[640px] h-[740px] w-full bg-cover"
        />
      </div>
      <div className="container h-full text-black">
        <div className="flex items-center h-full">
          <div className="max-w-[674px]">
            <p className="text-[35px] sm:text-[64px] sm:leading-[82px] leading-[52px] font-lora font-medium">
              {data?.title}
            </p>

            <p className="mt-8 text-base sm:text-[20px] leading-[25px] font-lora font-normal">
              {data?.caption}
            </p>
            <div className="mt-8">
              <div className="_form_84" id="_form_84"></div>

              <Script
                src="https://lessnoisemorevolume.activehosted.com/f/embed.php?id=84"
                type="text/javascript"
                charSet="utf-8"
              ></Script>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
