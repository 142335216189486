import React, { Fragment, useState, forwardRef, useEffect } from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import DatePicker from "react-datepicker";
import Layout from "../components/Layout";
import Hero from "../components/hero/HeroNewsletter";
import { SEO } from "../components/SEO";
import Footer from "../components/Footer";
import { SvgBar2 } from "../components/svg";
import { truncateString } from "../utils/common";
import "react-datepicker/dist/react-datepicker.css";
import "../styles/newsletter.scss";

const Newsletter = ({ data: { sanityNewsletterPage, allSanityArticle } }) => {
  const { nodes } = allSanityArticle;
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [list, setList] = useState(nodes);

  const removeDate = (str) => {
    const colonIndex = str.indexOf(":");
    if (colonIndex !== -1) {
      return truncateString(str.substring(colonIndex + 1), 30);
    }
    return truncateString(str, 20);
  };
  const CustomInput = forwardRef(({ value, onClick, label }, ref) => (
    <div
      className="border relative border-[#CECECE] justify-between rounded-xl flex py-[10px] px-3 cursor-pointer"
      onClick={onClick}
      ref={ref}
    >
      <p className="uppercase text-base font-normal px-3">{label}</p>
      <div className="flex items-center space-x-3">
        <p className={`${value ? "" : "px-10"}`}>{value}</p>
        <div className="flex items-center space-x-3">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 0V2H13V0H15V2H19C19.5523 2 20 2.44772 20 3V19C20 19.5523 19.5523 20 19 20H1C0.44772 20 0 19.5523 0 19V3C0 2.44772 0.44772 2 1 2H5V0H7ZM18 10H2V18H18V10ZM5 4H2V8H18V4H15V6H13V4H7V6H5V4Z"
              fill="black"
            />
          </svg>
          <svg
            width="8"
            height="5"
            viewBox="0 0 8 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 1L4 4L1 1"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
  ));

  useEffect(() => {
    if (fromDate && toDate) {
      const fd = new Date(fromDate).getTime();
      const td = new Date(toDate).getTime();
      const filterList = nodes.filter((item) => {
        const s = new Date(item._createdAt).getTime();

        return s >= fd && s <= td;
      });
      setList(filterList);
    } else {
      setList(nodes);
    }
  }, [fromDate, toDate]);

  const clearFilter = () => {
    setFromDate(null);
    setToDate(null);
  };

  return (
    <Fragment>
      <Layout>
        <Hero data={sanityNewsletterPage.heroSection} />
        <div className="container pt-11 flex flex-col md:flex-row md:justify-between md:items-center">
          <h1 className="font-lora text-2xl font-normal mb-2 md:mb-0">
            Recent Editions
          </h1>

          <div className="flex sm:items-center flex-col sm:flex-row">
            {fromDate && toDate && (
              <span
                className="px-4 text-sm cursor-pointer"
                onClick={clearFilter}
              >
                Clear
              </span>
            )}
            <DatePicker
              selected={fromDate}
              onChange={(date) => setFromDate(date)}
              customInput={<CustomInput label="From" />}
              key={"from"}
            />
            &nbsp; &nbsp;
            <DatePicker
              selected={toDate}
              onChange={(date) => setToDate(date)}
              customInput={<CustomInput label="To" />}
              key={"to"}
            />
          </div>
        </div>
        <section
          className={
            "container mx-auto pt-7 pb-10 grid grid-cols-1 xl:grid-cols-2 gap-x-7 xl:gap-x-11 gap-y-3 md:gap-y-[35px]"
          }
        >
          {list &&
            list.map((article, i) => {
              return (
                <div
                  className="flex flex-col md:flex-row bg-[url('../images/newsletter-bg.png')] bg-cover py-3 md:h-[187px]"
                  key={i}
                >
                  <div className="md:w-[229px] h-full">
                    <GatsbyImage
                      className="w-full h-full rounded-[12px]"
                      image={article?.featuredImage?.asset?.gatsbyImageData}
                      alt={article?.heading}
                    />
                  </div>
                  <div className="py-4 2xl:py-5 px-3 md:px-8 2xl:px-10">
                    <p className={"font-lora pb-[10px] text-base 2xl:text-lg"}>
                      {removeDate(article.heading)}
                    </p>
                    <SvgBar2 />
                    <p className="font-ssp text-xs 2xl:text-sm font-normal pt-[11px]">
                      {article?.summary && truncateString(article.summary, 90)}
                    </p>
                    <Link
                      to={`/${article.slug.current}`}
                      className="relative text-[13px] mt-3 font-robot w-[157px] h-[40px] flex items-center justify-center"
                    >
                      EXPLORE
                      <div className="absolute top-0 left-0 bottom-0 right-0 -z-10">
                        <StaticImage
                          src="../images/btn-border.svg"
                          alt="btn-border"
                          placeholder="blurred"
                          className="h-full w-full"
                          objectFit="contain"
                        />
                      </div>
                    </Link>
                  </div>
                </div>
              );
            })}
        </section>
      </Layout>
      <Footer />
    </Fragment>
  );
};

export default Newsletter;
export const Head = ({ data: { sanityNewsletterPage } }) => (
  <SEO metadata={sanityNewsletterPage?.pageMetadata || {}} />
);
export const query = graphql`
  query {
    sanityNewsletterPage {
      title
      id
      heroSection {
        thumbnail {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        title
        caption
      }

      pageMetadata {
        title
        schema
        keywords
        description
        canonical
        image {
          asset {
            url
          }
        }
      }
    }
    allSanityArticle(
      filter: {
        category: {
          elemMatch: { _id: { eq: "23215274-fa1d-46f7-8e91-041a0ff80ea8" } }
        }
      }
      sort: { order: DESC, fields: _createdAt }
    ) {
      nodes {
        templateType
        _createdAt
        featuredImage {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, formats: WEBP)
          }
        }
        summary
        slug {
          current
        }
        id
        heading
      }
    }
  }
`;
